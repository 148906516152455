import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { CSStextLink } from '../../UI'

// Color is passed from in-app modal views. So no pointer-events then.
const Container = styled.div`
  color: ${p => p.color || 'unset'};
  & a {
    pointer-events: ${p => (p.color ? 'none' : 'unset')};
  }
  @media (min-width: 640px) {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15) !important;
  }
`

const Body = styled.div`
  ${tw`text-sm sm:text-prebase`}
  user-select: none;
  & h2,
  & h3 {
    text-align: left;
  }
  & h2 {
    margin-block-start: 1.5em;
    line-height: 1.1;
    margin-block-end: 0.83em;
    ${tw`text-xl font-semibold`}
  }
  & p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  & a {
    ${CSStextLink}
    user-select: auto;
  }
  & ul {
    list-style: none;
    margin: 0;
    padding-left: 0.8em;
    & li {
      position: relative;
      padding-left: 1.3em;
      margin: 0.6em 0;
      line-height: 1.3;
      &::before {
        content: '•';
        position: absolute;
        left: 0;
      }
      & p {
        margin: 0;
        & b {
          color: black;
          font-weight: 500;
        }
      }
    }
  }
`

export default ({ data, color }) => {
  const body = documentToReactComponents(data.body.json)
  // console.log('data', data)
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
  const dated = new Date(data.dated).toLocaleDateString('ru', options)

  return (
    <Container
      className="sm:mt-8 sm:mb-12 sm:px-4 sm:px-8 md:px-10 lg:px-16 py-4 sm:py-8 md:py-8 lg:py-12 rounded-md"
      {...{ color }}
    >
      <h1 className="text-2xl sm:text-3xl leading-tight">{data.title}</h1>
      {data.subtitle && (
        <p className="mt-1 text-sm font-medium">{data.subtitle}</p>
      )}
      <Body>
        {body}
        {data.dated && (
          <p className="pt-4 text-sm font-medium">
            Данная редакция действует от {dated} г.
          </p>
        )}
      </Body>
    </Container>
  )
}

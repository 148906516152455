import React from 'react'
import LongreadUnisection from './LongreadUnisection'
import { SectionContainer } from '../../layout/Section'

const components = {
  LongreadUnisection: props => <LongreadUnisection {...props} />,
}

export default ({ sections, backgroundColor, color }) => {
  return sections.map((data, index) => {
    const componentName = data.__typename.replace('Contentful', '')
    const params = data.link
      ? { key: data.link, id: data.link.replace('#', '') }
      : { key: index }

    return (
      <SectionContainer {...params}>
        {components[componentName]({ data, backgroundColor, color })}
      </SectionContainer>
    )
  })
}

import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { createGlobalStyle } from 'styled-components'
import SEO from '../components/seo'
import Layout from '../components/layout'
import ContentSections from '../components/Contentful/ContentSections'

const CustomBackground = createGlobalStyle`
  html {
    background-color: ${p => p.backgroundColor};
  }
`

export default ({ data: { page } }) => {
  const { title, metaTitle, metaDescription, contentSections } = page

  // Handle in-app WebView styling. E.g. https://octopass.ru/terms?bghex=F1F2F6&colorhex=000000
  const [color, setColor] = useState()
  const [backgroundColor, setBackgroundColor] = useState()
  const [isReady, setIsReady] = useState()
  useEffect(() => {
    if (typeof window === 'undefined') return
    const p = new URL(document.location).searchParams
    const [col, bgCol] = [
      p.get('colorhex') && `#${p.get('colorhex')}`,
      p.get('bghex') && `#${p.get('bghex')}`,
    ]
    if (col) setColor(col)
    if (bgCol) setBackgroundColor(bgCol)
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage('ready')
    setIsReady(true)
  }, [])
  const headless = Boolean(color)

  return !isReady ? null : (
    <Layout headless={headless}>
      <SEO title={metaTitle || title} description={metaDescription} />
      {backgroundColor && <CustomBackground {...{ backgroundColor }} />}
      <ContentSections sections={contentSections} {...{ color }} />
    </Layout>
  )
}

export const query = graphql`
  query($link: String!) {
    page: contentfulPage(link: { eq: $link }) {
      title
      metaTitle
      metaDescription
      link
      contentSections {
        ... on Node {
          ... on ContentfulLongreadUnisection {
            # __typename
            # updatedAt
            title
            subtitle
            dated
            body {
              json
            }
          }
        }
      }
    }
  }
`
